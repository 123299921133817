import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, {
            fixed: "",
            style: {"padding":"0"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, { slot: "start" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_back_button, { "default-href": "/profile" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t('notifications')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              ($setup.loading)
                ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (index) => {
                        return _createVNode(_component_ion_item, { key: index }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_skeleton_text)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("h3", null, [
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"80%"}
                                  })
                                ]),
                                _createElementVNode("p", null, [
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"60%"}
                                  })
                                ]),
                                _createElementVNode("p", null, [
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"30%"}
                                  })
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1024)
                      }), 64))
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.allNotifications, (notification) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: notification.id,
                          "router-link": {
              name: 'NotificationDetailPage',
              params: { id: notification.id }
            },
                          button: "",
                          detail: ""
                        }, {
                          default: _withCtx(() => [
                            (notification.photo)
                              ? (_openBlock(), _createBlock(_component_ion_thumbnail, {
                                  key: 0,
                                  slot: "start"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("img", {
                                      src: notification.photoLink
                                    }, null, 8, _hoisted_1)
                                  ]),
                                  _: 2
                                }, 1024))
                              : (_openBlock(), _createBlock(_component_ion_icon, {
                                  key: 1,
                                  icon: $setup.notificationsOutline,
                                  slot: "start"
                                }, null, 8, ["icon"])),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("h3", null, _toDisplayString(notification.title), 1),
                                _createElementVNode("p", null, _toDisplayString(notification.content), 1),
                                _createElementVNode("p", null, [
                                  _createElementVNode("small", null, _toDisplayString($setup.getRelativeDate(notification.scheduledToSendAt)), 1)
                                ])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["router-link"]))
                      }), 128))
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}